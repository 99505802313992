import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="tes-mail">
      <div className='tes-mail-logo'>
        <a href="https://rbiuro.pl" target="_blank">
          <img src="https://rbiuro.pl/static/media/logo.3de61b0ee905e2c9e0d8.png" alt="LOGO" />
        </a>
      </div>
      <div className='tes-mail-content'>
        <a className='tes-mail-content__button' href="https://www.ovhcloud.com/pl/mail/">
          Przejdź do poczty
        </a>
      </div>
    </div>
  );
}

export default App;
